<template>
  <nav id="navbar" class="navbar" :class="getClass">
    <router-link to="/" @click="closeAll">
      <img
        :src="require(`@/assets/img/${this.logoURL}`)"
        class="navbar__logo"
        alt="University of Georgia"
      />
    </router-link>
    <div class="navbar__right">
      <ul class="navbar__right-links">
        <li
          class="navbar__right-links-item"
          v-for="audience in audiences"
          :key="audience.name"
        >
          <a
            v-if="audience.external"
            class="navbar__right-links-link"
            :href="audience.link"
            >{{ audience.name }}</a
          >
          <router-link
            v-else
            class="navbar__right-links-link"
            :to="audience.link"
            @click="closeAll"
            >{{ audience.name }}</router-link
          >
        </li>
        <li class="navbar__right-links-item">
          <router-link
            class="navbar__right-links-link"
            to="/directory"
            @click="closeAll"
            >A–Z Directory</router-link
          >
        </li>
      </ul>
      <div v-if="this.windowWidth >= 768" class="navbar__right-buttons">
        <button
          v-show="!searchOpen"
          class="navbar__right-buttons-search"
          @click="toggleSearchOpen"
        >
          <i class="bi bi-search"></i> Search
        </button>
        <form
          autocomplete="off"
          v-show="searchOpen"
          @submit.prevent="submitSearch"
          @keyup.esc="this.searchOpen = !this.searchOpen"
        >
          <i
            id="bi-search-input"
            @click="submitSearch"
            class="bi bi-search"
          ></i>
          <input
            type="text"
            placeholder="Search"
            id="search"
            ref="searchInput"
            v-model="searchInputData"
          />
        </form>
        <button
          class="navbar__right-buttons-menu"
          v-if="this.searchOpen"
          @click="this.searchOpen = !this.searchOpen"
          style="z-index:999999;"
        >
          <i class="bi bi-x"></i>
        </button>

        <button
          v-else
          class="navbar__right-buttons-menu"
          @click="toggleMenuOpen"
        >
          <i v-if="menuOpen" class="bi bi-x"></i>
          <i v-else class="bi bi-list"></i>
          {{ menuButtonText }}
        </button>
      </div>
      <div v-else class="navbar__right-buttons">
        <button
          v-show="!searchOpen"
          class="navbar__right-buttons-search"
          @click="toggleSearchOpen"
        >
          <i class="bi bi-search"></i>
        </button>
        <form
          autocomplete="off"
          v-show="searchOpen"
          @submit.prevent="submitSearch"
          @keyup.esc="this.searchOpen = !this.searchOpen"
        >
          <i id="bi-search-input" class="bi bi-search"></i>
          <input
            type="text"
            placeholder="Search"
            id="search"
            ref="searchInput"
            v-model="searchInputData"
          />
        </form>

        <button
          class="navbar__right-buttons-menu"
          v-if="this.searchOpen"
          @click="this.searchOpen = !this.searchOpen"
          style="z-index:999999;"
        >
          <i class="bi bi-x"></i>
        </button>

        <button
          v-else
          class="navbar__right-buttons-menu"
          @click="toggleMenuOpen"
        >
          <i v-if="menuOpen" class="bi bi-x"></i>
          <i v-else class="bi bi-list"></i>
        </button>
      </div>
    </div>
    <NavMenu
      :menuItems="menuItems"
      :audiences="audiences"
      :top="navHeight"
      v-if="menuOpen"
      @menuItemClicked="closeAll"
      data-aos="fade-left"
      data-aos-duration="500"
    ></NavMenu>
  </nav>
  <div v-if="menuOpen" id="overlay" @click="closeMenu" data-aos="fade"></div>
</template>
<script>
import NavMenu from "@/components/NavMenu.vue";
export default {
  name: "Navbar",
  props: {
    emergencyBanner: { type: Boolean, default: false },
    homepage: { type: Boolean, default: false },
    transparent: { type: Boolean, default: false },
  },
  components: {
    NavMenu,
  },
  emits: ["search-submit"],
  data() {
    return {
      logoURL: "GEORGIA-FS-FC.png",
      scrollPos: null,
      distance: 50,
      searchOpen: false,
      menuOpen: false,
      menuButtonText: "Menu",
      searchInputData: "",
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
      navHeight: null,
      stuck: false,
      menuItems: {
        "About UGA": {
          name: "About",
          link: "/about",
          items: [
            { name: "Our Campuses", link: "/about/campuses" },
            { name: "Our Mission", link: "/about/mission" },
            { name: "Facts & Figures", link: "/about/facts" },
            { name: "Academic Excellence", link: "/about/academic-excellence" },
            { name: "Research and Innovation", link: "/about/research" },
            { name: "Top Value", link: "/about/value" },
            {
              name: "History & Traditions",
              link: "/about/history-and-traditions",
            },
            { name: "A–Z Directory", link: "/directory" },
          ],
        },
        Admissions: {
          name: "Admissions",
          items: [
            {
              name: "Undergraduate Admissions",
              link: "https://www.admissions.uga.edu/",
            },
            { name: "Graduate School", link: "https://grad.uga.edu/" },
            { name: "Visit UGA", link: "https://visit.uga.edu/" },
            { name: "Tuition & Financial Aid", link: "https://osfa.uga.edu/" },
          ],
        },
        Academics: {
          name: "Academics",
          items: [
            {
              name: "Schools & Colleges",
              link: "/schools",
            },
            { name: "Majors", link: "https://majors.admissions.uga.edu/" },
            {
              name: "Graduate Programs",
              link: "https://grad.uga.edu/index.php/degrees/",
            },
            {
              name: "AU/UGA Medical Partnership",
              link: "https://medicalpartnership.usg.edu/",
            },
            { name: "Online Learning", link: "https://online.uga.edu/" },
            { name: "Study Abroad", link: "https://studyaway.uga.edu/" },
            {
              name: "Active Learning",
              link: "https://activelearning.uga.edu/",
            },
          ],
        },
        Research: {
          name: "Research",
          link: "/about/research",
          items: [
            { name: "Office of Research", link: "https://research.uga.edu/" },
            {
              name: "Centers & Institutes",
              link: "https://research.uga.edu/centers-institutes/",
            },
            { name: "Undergraduate Research", link: "http://curo.uga.edu/" },
            {
              name: "Graduate Research",
              link:
                "https://grad.uga.edu/index.php/current-students/student-research/",
            },
            { name: "University Libraries", link: "https://www.libs.uga.edu/" },
          ],
        },
        Outreach: {
          name: "Outreach",
          external: true,
          items: [
            {
              name:
                "Office of the Vice President for Public Service and Outreach",
              link: "https://outreach.uga.edu/",
            },
            {
              name: "Public Service and Outreach Units",
              link: "https://outreach.uga.edu/about/",
            },
            {
              name: "College and School Outreach Offices",
              link: "https://outreach.uga.edu/about/history/",
            },
            {
              name: "Serving the State of Georgia",
              link: "https://wearegeorgia.uga.edu/",
            },
          ],
        },
        Athletics: {
          name: "Athletics",
          link: "https://georgiadogs.com/",
          external: true,
        },
      },
      audiences: [
        {
          name: "Students",
          nameLong: "Current Students",
          link: "https://studentaffairs.uga.edu/",
          external: true,
        },
        {
          name: "Faculty & Staff",
          link: "/faculty-and-staff",
          external: false,
        },
        {
          name: "Alumni",
          link: "https://alumni.uga.edu/",
          external: true,
        },
        {
          name: "Community",
          nameLong: "Community Members",
          link: "/community",
          external: false,
        },
      ],
    };
  },
  methods: {
    logoColor() {
      this.logoURL = "GEORGIA-FS-FC.png";
    },
    logoWhite() {
      this.logoURL = "GEORGIA-FS-CW.png";
    },
    setLogoColor() {
      this.logoColor();
      if (this.transparent) {
        if (this.stuck) {
          this.logoColor();
        } else {
          this.logoWhite();
        }
      }
      // if (this.homepage) {
      //   if (this.emergencyBanner) {
      //     this.logoColor();
      //   } else {
      //     if (this.scrollPos >= this.distance) {
      //       this.logoColor();
      //     }
      //     if (this.scrollPos < this.distance) {
      //       this.logoWhite();
      //       if (this.menuOpen) {
      //         this.logoColor();
      //       }
      //     }
      //   }
      // } else {
      //   this.logoColor();
      // }
      if (this.windowWidth < 768) {
        this.logoColor();
      }
      if (this.menuOpen) {
        this.logoColor();
      }
    },
    getHeight() {
      let nav = document.getElementById("navbar");
      let total = nav.offsetHeight;
      this.navHeight = total;
    },
    updateScroll() {
      this.scrollPos = window.scrollY;
      this.stuck = true;

      let emergencyBanner = document.getElementById("emergency");
      // console.log(window.scrollY + '>=' + document.getElementById('emergency').offsetHeight);
      if (
        window.scrollY >
        (emergencyBanner != undefined ? emergencyBanner.offsetHeight : 0)
      ) {
        this.stuck = true;
      } else {
        this.stuck = false;
      }
      //if scrolled or menu is open or emergencyBanner or not on homepage

      this.setLogoColor();
    },

    unlockScroll() {
      document.body.style.overflowY = "auto";
      document.body.style.position = "relative";
    },
    lockScroll() {
      document.body.style.overflowY = "hidden";
      document.body.style.position = "unset";
    },
    openMenu() {
      this.menuOpen = true;
      this.searchOpen = false;
      this.menuButtonText = "Close";
      this.updateScroll();
      this.lockScroll();
    },
    closeMenu() {
      this.menuOpen = false;
      this.menuButtonText = "Menu";
      this.updateScroll();
      this.unlockScroll();
    },
    toggleMenuOpen() {
      this.menuOpen = !this.menuOpen;
      if (this.menuOpen) {
        this.openMenu();
      } else {
        this.closeMenu();
      }
    },
    toggleSearchOpen() {
      this.searchOpen = !this.searchOpen;
      if (this.searchOpen) {
        this.$nextTick(() => {
          this.$refs.searchInput.focus();
        });
      }
    },
    closeSearch() {
      this.searchOpen = false;
      this.searchInputData = "";
    },
    closeAll() {
      this.closeSearch();
      this.closeMenu();
    },
    submitSearch(e) {
      e.preventDefault();
      this.closeMenu();

      this.$router.push({
        name: "Search",
        query: { q: this.searchInputData },
      });
      this.closeSearch();

      // this.$emit('search-submit', this.searchInputData);
    },
    onResize() {
      this.windowHeight = window.innerHeight;
    },
    navbarMargin() {
      let navBar = document.getElementById("navbar");
      if (this.transparent) {
        navBar.style.marginBottom = "-" + this.navHeight + "px";
      } else {
        navBar.style.marginBottom = "0";
      }
    },
  },
  beforeMount() {
    this.setLogoColor();
  },
  mounted() {
    this.getHeight();
    window.addEventListener("scroll", this.updateScroll);
    this.navbarMargin();
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.onResize);
  },
  beforeUpdate() {
    this.getHeight();
    this.setLogoColor();
    this.navbarMargin();
  },
  updated() {},
  computed: {
    getClass() {
      let cls = [];
      // if (this.emergencyBanner || !this.homepage || this.stuck) {
      if (this.stuck) {
        cls.push("sticky");
        cls.push("shadow");
      }
      if (this.transparent) {
        cls.push("transparent");
      }
      if (this.menuOpen) {
        cls.push("open");
      }
      return cls;
    },
    getLinkClass() {
      let cls = ["navbar__right-links-link"];
      if (this.emergencyBanner || !this.homepage) {
        return cls;
      } else if (this.scrollPos <= this.distance && !this.menuOpen) {
        cls.push("white");
      }
      return cls;
    },
  },
};
</script>
<style scoped lang="scss">
#overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  background-color: black;
  opacity: 0.7;
  z-index: 9;
  position: fixed;
  backdrop-filter: blur(10px);
}
.navbar {
  position: sticky;
  background-color: $white;
  top: 0;
  height: 67px;
  color: $white;
  width: 100%;
  z-index: 99;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  transition: all ease-in-out 0.3s;
  padding-top: 0;
  padding-bottom: 0;
  @include media-breakpoint-down(md) {
    margin-bottom: 0 !important;
  }
  &.transparent {
    // position: absolute;
    &.sticky,
    &.open {
      position: sticky;
      background: $white;
      .navbar__right-links {
        &-item {
          border-color: fade-out($black, 0.8);
        }
        &-link {
          color: $black;
        }
      }
    }
    .navbar__right-links {
      &-item {
        border-color: fade-out($white, 0.8);
      }
      &-link {
        color: $white;
      }
    }
  }
  &.open {
    background-color: $white;
    color: $black;
    &:not(.sticky) {
      position: fixed;
    }
  }
  &__logo {
    padding-left: 1.5rem;
    margin-top: 15px;
    width: 140px;
  }
  &__right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    &-links {
      display: none;
    }
    &-buttons {
      margin-bottom: 0.75rem;
      display: flex;
      flex-direction: row;

      &-search,
      &-menu {
        font-family: $font-family-serif;
        font-size: 1.1875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 2.0625rem;
        letter-spacing: 0em;
        text-align: left;
        padding: 1rem;
        border: none;
        outline: none;
        & > i {
          font-size: 1.5rem;
        }
      }
      &-search {
        background-color: $white;
      }
      &-menu {
        color: $white;
        background-color: $red;
      }
    }
  }
}

#search {
  height: 100%;
  padding: 1rem;
  border: none;
  outline: none;
  width: 82%;
  position: absolute;
  left: 0;
}
@include media-breakpoint-up(md) {
  .navbar {
    // position: fixed;
    background: $white;
    height: unset;
    top: 0;
    width: 100%;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all ease-in-out 0.3s;
    &__logo {
      padding-left: 1.5rem;
      width: 280px;
      // height: fit-content;
    }
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding-right: 1rem;
      padding-top: 5px;
      padding-bottom: 5px;
      &-links {
        display: flex;
        padding: 0.5rem 0 0 0;
        margin: 0 0 0.875rem 0;
        list-style: none;
        &-item {
          font-family: $font-family-sans-serif;
          font-size: 0.8125rem;
          line-height: 1.5;
          font-weight: $font-weight-normal;
          border-right: 1px solid fade-out($black, 0.9);
          transition: border-color 100ms ease-in;
          &:last-of-type {
            border: none;
          }
        }
        &-link {
          color: $black;
          display: block;
          padding: 0 0.5rem;
          &:hover,
          &:focus {
            color: $red;
          }
        }
      }
      &-buttons {
        margin-bottom: 0.75rem;
        display: flex;
        flex-direction: row;
        &-search,
        &-menu {
          font-family: $font-family-serif;
          font-size: 1.1875rem;
          font-style: normal;
          font-weight: 400;
          line-height: 2.0625rem;
          letter-spacing: 0em;
          text-align: left;
          padding: 1rem;
          border: none;
          outline: none;
          display: flex;
          align-items: center;
          & > i {
            font-size: 2rem;
            margin-right: 0.25rem;
          }
        }
        &-search {
          background-color: $white;
        }
        &-menu {
          color: $white;
          background-color: $red;
        }
      }
    }
  }

  .white {
    color: $white;
  }
  .sticky {
    position: sticky;
    background-color: $white;
  }
  .transparent {
    background-color: transparent;
  }
  #bi-search-input {
    cursor: pointer;
    font-size: 2rem;
    padding-left: 1rem;
    color: $black;
  }
  form {
    background-color: $white;
  }

  #search {
    width: 400px;
    height: 100%;
    font-family: $font-family-serif;
    font-size: 1.1875rem;
    font-style: normal;
    font-weight: 400;
    line-height: 2.0625rem;
    letter-spacing: 0em;
    text-align: left;
    padding: 1rem;
    border: none;
    outline: none;
    position: unset;
  }

  // .fade-enter-active,
  // .fade-leave-active {
  //   transition: opacity 0.3s;
  // }
  // .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  //   opacity: 0;
  // }
}
</style>
