/* Vue */
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { createMetaManager, plugin as metaPlugin } from "vue-meta";
import { createGtm } from "@gtm-support/vue-gtm";

/* Axios */
import axios from "axios";
import VueAxios from "vue-axios";

/* Animate on Scroll */
import AOS from "aos";
import "aos/dist/aos.css";

import "@/scss/global.scss";
// import 'bootstrap';

const app = createApp(App);

app
  .use(router)
  .use(
    createGtm({
      // See https://github.com/gtm-support/vue-gtm#configuration for options
      id: "GTM-TFFQL3Q",
      defer: false,
      compatibility: false,
      enabled: true,
      debug: true,
      loadScript: true,
    })
  )
  .use(createMetaManager())
  .use(metaPlugin)
  .use(VueAxios, axios)
  .use(AOS.init());

router.isReady().then(() => app.mount("#app"));

app.config.globalProperties.$filters = {
  slugify(text) {
    // https://gist.github.com/codeguy/6684588
    return text
      .toString()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .toLowerCase()
      .trim()
      .replace(/\s+/g, "-")
      .replace(/[^\w-]+/g, "")
      .replace(/--+/g, "-");
  },
  isExternal(url) {
    var pattern = /^((https?):\/\/)/;
    return typeof url === "string" && pattern.test(url);
  },
};
