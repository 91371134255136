<template>
  <component
    v-if="$filters.isExternal(href)"
    :is="type"
    :class="computedClass"
    :href="href"
    :disabled="isDisabled"
  >
    <slot />
  </component>
  <router-link
    v-else
    :class="computedClass"
    :to="href"
    :disabled="isDisabled"
  >
    <slot />
  </router-link>
</template>
<script>
export default {
  name: 'Btn',
  data() {
    return {
      isDisabled: null,
    }
  },
  props: {
    color: {
      type: String,
      default: 'black',
    },
    outline: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: null,
    },
    arrow: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: null,
    },
    href: {
      type: String,
      default: null,
    },
  },
  computed: {
    type() {
      if (this.href) {
        return 'a';
      } else {
        return 'button';
      }
    },
    computedClass() {
      let cls = ['btn'];
      if (this.outline) {
        cls.push(`btn-outline-${this.color}`);
      } else {
        cls.push(`btn-${this.color}`);
      }
      if (this.size)
        cls.push(`btn-${this.size}`);
      if (this.arrow)
        cls.push('btn-arrow');
      if (this.href && this.disabled) {
        cls.push('disabled');
      }
      return cls;
    },
  },
  methods: {
  },
  created: function(){
    if (this.disabled && !this.href) {
      this.isDisabled = true;
    }
  }
};
</script>
<style scoped lang="scss">
</style>
