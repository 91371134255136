<template>
  <div
    class="menu"
    :style="{ top: this.top + 'px', height: `calc( 100% - ${this.top}px )` }"
  >
    <div class="menu-main accordion accordion-flush" id="menuAccordion">
      <ul id="menu" class="menu-main__container accordion accordion-flush">
        <li
          class="menu-main__top-container accordion-item"
          v-for="(group, index) in menuItems"
          :key="group.name"
          :id="$filters.slugify(group.name)"
        >
          <!-- top-level link and submenu -->
          <div
            v-if="group.link && group.items"
            class="menu-main__top-link-dropdown"
          >
            <router-link
              class="menu-main__link"
              :to="group.link"
              style="width:80%"
              @click="emitLinkClick"
            >{{ group.name }}</router-link>
            <span class="main-menu__divider"></span>
            <button
              class="menu-main__button accordion-button"
              :class="{ collapsed: index !== 0 }"
              style="width:fit-content"
              type="button"
              id="right-dropdown"
              data-bs-toggle="collapse"
              :data-bs-target="`#` + $filters.slugify(group.name) + `-collapse`"
              :aria-expanded="false"
              :aria-controls="$filters.slugify(group.name) + `-collapse`"
            ></button>
          </div>

          <!-- submenu only -->
          <div
            v-else-if="group.items"
            class="menu-main__top accordion-header"
            :id="$filters.slugify(group.name) + `-heading`"
          >
            <button
              class="menu-main__button accordion-button"
              :class="{ collapsed: index !== 0 }"
              type="button"
              data-bs-toggle="collapse"
              :data-bs-target="`#` + $filters.slugify(group.name) + `-collapse`"
              :aria-expanded="false"
              :aria-controls="$filters.slugify(group.name) + `-collapse`"
            >
              {{ group.name }}
            </button>
          </div>
          
          <!-- no submenu, internal link -->
          <router-link
            v-else-if="!group.external"
            :to="group.link"
            @click="emitLinkClick"
            class="menu-main__link accordion-button accordion-button-right"
          >
            {{ group.name }}
          </router-link>
          
          <!-- no submenu, external link -->
          <a
            v-else
            :href="group.link"
            class="menu-main__link accordion-button accordion-button-right"
            >{{ group.name }}</a
          >

          <ul
            v-if="group.items"
            class="menu-main__submenu accordion-collapse collapse"
            :id="$filters.slugify(group.name) + `-collapse`"
            :class="{ show: index === 0 }"
          >
            <li
              class="menu-main__submenu-item"
              :aria-labelledby="$filters.slugify(group.name) + `-heading`"
              v-for="item in group.items"
              :key="item.name"
              data-bs-parent="#menu"
            >
              <a
                v-if="$filters.isExternal(item.link)"
                class="menu-main__submenu-item-link"
                :href="item.link"
                >{{ item.name }}</a
              >
              <router-link
                v-else
                class="menu-main__submenu-item-link"
                :data-external="$filters.isExternal"
                @click="emitLinkClick"
                :to="item.link"
                >{{ item.name }}</router-link
              >
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <div class="menu-secondary">
      <div class="menu-secondary__title">
        Resources for:
      </div>
      <ul class="menu-secondary__list">
        <li
          class="menu-secondary__list-item"
          v-for="audience in audiences"
          :key="audience.name"
        >
          <a
            v-if="audience.external"
            class="menu-secondary__list-item-link"
            :href="audience.link"
            >{{ audience.nameLong ? audience.nameLong : audience.name }}</a
          >
          <router-link
            v-else
            class="menu-secondary__list-item-link"
            :to="audience.link"
            @click="emitLinkClick"
            >{{
              audience.nameLong ? audience.nameLong : audience.name
            }}</router-link
          >
        </li>
      </ul>
      <div class="mt-3">
        <a
          class="btn btn-sm btn-outline-light"
          href="https://gail.uga.edu/commit?utm_campaign=2022_giving&utm_medium=web&utm_source=UGA_Home&utm_content=nav_button"
        >
          <font-awesome-icon
            :icon="['fas', 'hand-holding-dollar']"
            fixed-width
            aria-hidden
            class="me-1"
          />
          Make a Gift</a
        >
      </div>
    </div>
  </div>
</template>
<script>
import { Collapse } from 'bootstrap/js/dist/collapse';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faHandHoldingDollar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faHandHoldingDollar);

export default {
  name: 'NavMenu',
  props: {
    menuItems: { type: Object, required: true },
    audiences: { type: Object, required: true },
    top: { type: Number, required: true },
  },
  components: {
    FontAwesomeIcon,
  },
  mounted() {
    this.setTop();
  },
  methods: {
    resetScroll() {
      document.body.style.overflowY = 'auto';
    },
    emitLinkClick() {
      this.$emit('menuItemClicked');
    },
    setTop() {
      document.querySelector('#menu').style.top = `${top}px`;
    },
  },
  computed: {},
};
</script>
<style scoped lang="scss">
.main-menu__divider {
  width: 1px;
  height: 2rem;
  border-right: 2px solid fade-out($white, .85);
}
.menu {
  background-color: $black;
  padding: 2rem 3rem;
  z-index: 10000;
  overflow-y: auto;
  position: fixed;
  width: 100%;
  @include media-breakpoint-up(md) {
    width: 400px;
    // top: 132px;
    right: 0;
    height: calc(100vh - 132px);
  }
  &-main {
    &__container {
      list-style: none;
      padding-left: 0;
      margin-bottom: 0;
    }
    &__top {
      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
      &-container {
        background-color: $black;
        border-bottom: 1px solid $grey;
        padding: 0.5rem 0;
      }
      &-link-dropdown {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
    }

    &__button,
    &__link,
    &__link:not(.collapsed),
    .accordion-button:not(.collapsed) {
      background-color: transparent;
      color: $white;
      font-family: $font-family-block;
      font-weight: 500;
      font-size: 1.75rem;
      line-height: 1;
      text-transform: uppercase;
      padding: 0.75rem;
      border-radius: .75rem !important;
      @include media-breakpoint-up(md) {
        font-size: 2rem;
      }
      &.accordion-button-right:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(186,12,47)' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        transform: rotate(-90deg);
      }
      &:after {
        background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(186,12,47)' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
      }
      &:not(.collapsed) {
        color: $white;
        &:after {
          background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='rgb(186,12,47)' class='bi bi-chevron-down' viewBox='0 0 16 16'><path fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/></svg>");
        }
      }

      &:hover,
      &:active {
        background-color: rgb(27, 27, 27) !important;
      }
      &:focus {
        box-shadow: none;
      }
    }
    &__submenu {
      list-style-type: none;
      margin-bottom: 0.5rem;
      padding-left: .75rem;
      @include media-breakpoint-up(md) {
        // margin-bottom: 0.5rem;
      }
      &-item {
        padding: 0.5rem 0;
        &:last-of-type {
          // padding-bottom: 1rem;
        }
      }

      &-item-link {
        display:  inline-block;
        font-family: $font-family-serif;
        font-size: 1.125rem;
        line-height: 1.5;
        font-weight: 400;
        color: $white;
        @include media-breakpoint-up(md) {
          font-size: 1.125rem;
          line-height: 1.5;
        }
        &:hover,
        &:active {
          filter: brightness(0.6);
        }
      }
    }
  }
  &-secondary {
    margin-top: 2rem;
    &__title {
      color: $light-gray;
      font-family: $font-family-sans-serif;
      font-weight: $font-weight-normal;
      font-size: 1.25rem;
      margin-bottom: 0.5rem;
      @include media-breakpoint-up(md) {
        font-size: 1.25rem;
      }
    }
    &__list {
      list-style: none;
      margin: 0;
      padding: 0;
      &-item {
        font-family: $font-family-serif;
        font-size: 1.125rem;
        font-weight: $font-weight-normal;
        @include media-breakpoint-up(md) {
          font-size: 1.25rem;
        }
        &-link {
          display: inline-block;
          color: $white;
          padding: 0.5rem 0;
          &:hover,
          &:active {
            filter: brightness(0.8);
          }
        }
      }
    }
  }
}
</style>
