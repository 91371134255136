import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import(/* webpackChunkName: "home" */ "@/views/Home.vue"),
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/About.vue"),
  },
  {
    path: "/about/mission",
    name: "Mission",
    component: () =>
      import(/* webpackChunkName: "mission" */ "@/views/Mission.vue"),
  },
  {
    path: "/about/academic-excellence",
    name: "Academic-excellence",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(
        /* webpackChunkName: "academic-excellence" */ "@/views/Academic-Excellence.vue"
      ),
  },
  {
    path: "/about/facts",
    name: "Facts",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "facts" */ "@/views/Facts.vue"),
  },
  {
    path: "/about/history-and-traditions",
    name: "History-and-traditions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "History" */ "@/views/History.vue"),
  },
  {
    path: "/about/research",
    name: "Research",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "research" */ "@/views/Research.vue"),
  },
  {
    path: "/about/value",
    name: "Value",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Value.vue"),
  },
  {
    path: "/about/serving-georgia",
    name: "Serving-georgia",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "@/views/Serving-Georgia.vue"),
  },
  {
    path: "/about/campuses",
    name: "Campuses",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "campuses" */ "@/views/Campuses.vue"),
  },
  {
    path: "/schools",
    name: "Schools",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "schools" */ "@/views/SchoolsColleges.vue"),
  },
  {
    path: "/directory",
    name: "Directory",
    component: () =>
      import(/* webpackChunkName: "directory" */ "@/views/A-Z.vue"),
  },
  // {
  //   path: '/directory/location/:name',
  //   component: () =>
  //     import(/* webpackChunkName: "directory" */ '@/views/Location.vue'),
  // },
  {
    path: "/faculty-and-staff",
    name: "Faculty-and-staff",
    component: () =>
      import(
        /* webpackChunkName: "faculty-staff" */ "@/views/FacultyStaff.vue"
      ),
  },
  {
    path: "/community",
    name: "Community",
    component: () =>
      import(/* webpackChunkName: "community" */ "@/views/Community.vue"),
  },
  {
    path: "/search",
    name: "Search",
    component: () =>
      import(/* webpackChunkName: "search" */ "@/views/Search.vue"),
  },
  {
    path: "/holiday-schedule",
    name: "Holiday-schedule",
    component: () =>
      import(
        /* webpackChunkName: "holiday-schedule" */ "@/views/Holiday-Schedule.vue"
      ),
  },
  {
    path: "/weather-policy",
    name: "Weather-policy",
    component: () =>
      import(
        /* webpackChunkName: "weather-policy" */ "@/views/Weather-Policy.vue"
      ),
  },
  {
    path: "/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/404.vue"),
  },
  {
    path: "/403",
    name: "403",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/403.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/500.vue"),
  },
  {
    path: "/download-app",
    name: "Download-app",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/Download-App.vue"),
  },
  {
    path: "/social-media",
    name: "Social-media",
    component: ()=>
      import(/* webpackChunkName: "social-media" */ "@/views/Social-media.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "not-found" */ "@/views/404.vue"),
  },
];

const scrollBehavior = (to, from, savedPosition) => {
  return savedPosition || { top: 0, left: 0 };
};

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior,
  routes,
});

export default router;
