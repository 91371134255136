<template>
  <footer class="ugafooter" :class="borderClass">
    <div class="ugafooter__container" :class="containerClass">
      <div class="ugafooter__row ugafooter__row--primary row">
        <div class="ugafooter__logo col-md-5">
          <a class="ugafooter__logo-link" href="https://www.uga.edu/"
            >University of Georgia</a
          >
        </div>
        <nav class="ugafooter__links col-md-7">
          <ul class="ugafooter__links-list">
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="https://www.uga.edu/schools/"
                >Schools and Colleges</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="https://peoplesearch.uga.edu/"
                >Directory</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a class="ugafooter__links-list-link" href="https://my.uga.edu/"
                >MyUGA</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="http://hr.uga.edu/applicants/"
                >Employment Opportunities</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="https://mc.uga.edu/policy/trademark"
                >Copyright and Trademarks</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="https://eits.uga.edu/access_and_security/infosec/pols_regs/policies/privacy/"
                >Privacy</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="mailto:webmaster@uga.edu"
                >Website Feedback</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="https://gbi.georgia.gov/human-trafficking-notice"
                >Human Trafficking Notice</a
              >
            </li>
            <li class="ugafooter__links-list-item">
              <a
                class="ugafooter__links-list-link"
                href="https://legal.uga.edu/guidance/compliance-ethics-reporting-hotline"
                >Reporting Hotline</a
              >
            </li>
          </ul>
        </nav>
      </div>
      <div class="ugafooter__row ugafooter__row--secondary row">
        <nav class="ugafooter__social col-md-7">
          <span class="ugafooter__social-label">#UGA on</span>
          <a
            class="ugafooter__social-link"
            aria-label="UGA on Facebook"
            href="https://www.facebook.com/universityofga/"
          >
            <font-awesome-icon
              :icon="['fab', 'facebook-f']"
              fixed-width
              title="Facebook"
              aria-hidden
            />
          </a>
          <a
            class="ugafooter__social-link"
            aria-label="UGA on Twitter"
            href="https://twitter.com/universityofga"
          >
            <font-awesome-icon
              :icon="['fab', 'x-twitter']"
              fixed-width
              title="X-Twitter"
              aria-hidden
            />
          </a>
          <a
            class="ugafooter__social-link"
            aria-label="UGA on Instagram"
            href="https://www.instagram.com/universityofga/"
          >
            <font-awesome-icon
              :icon="['fab', 'instagram']"
              fixed-width
              title="Instagram"
              aria-hidden
            />
          </a>
          <a
            class="ugafooter__social-link"
            aria-label="UGA on YouTube"
            href="https://www.youtube.com/user/UniversityOfGeorgia"
          >
            <font-awesome-icon
              :icon="['fab', 'youtube']"
              fixed-width
              title="YouTube"
              aria-hidden
            />
          </a>
          <a
            class="ugafooter__social-link"
            aria-label="UGA on LinkedIn"
            href="https://www.linkedin.com/school/university-of-georgia/"
          >
            <font-awesome-icon
              :icon="['fab', 'linkedin-in']"
              fixed-width
              title="LinkedIn"
              aria-hidden
            />
          </a>
        </nav>
        <div class="ugafooter__address col-md-5">
          &copy; University of Georgia, Athens, GA 30602<br />
          <a class="ugafooter__address-telephone" href="tel:7065243000"
            >706&#8209;542&#8209;3000</a
          >
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faXTwitter,
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faYoutube,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

library.add(
  faFacebookF,
  faFacebookF,
  faXTwitter,
  faTwitter,
  faInstagram,
  faSnapchatGhost,
  faYoutube,
  faLinkedinIn
);

export default {
  name: "Footer",
  props: {
    border: {
      type: String,
      default: "red",
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    FontAwesomeIcon,
  },
  computed: {
    borderClass() {
      let borderClass = [];
      if (this.border) {
        borderClass.push("ugafooter--border-" + this.border);
      }
      return borderClass;
    },
    containerClass() {
      let containerClass = [];
      if (this.fullWidth) {
        containerClass.push("container-fluid");
      } else {
        containerClass.push("container");
      }
      return containerClass;
    },
  },
};
</script>
<style scoped lang="scss">
@mixin footer-link-style {
  color: fade-out($white, 0.4);
  text-decoration: none;
  transition: color 100ms ease-in;
  &:hover {
    color: $white;
    text-decoration: none;
  }
}
.ugafooter {
  box-sizing: border-box;
  padding: 48px 0;
  border-width: 4px 0 0;
  border-style: solid;
  background: url("~@/assets/img/background-arch.jpg") $black center center
    no-repeat;
  background-size: cover;
  font-size: 16px;
  font-family: $font-family-serif;
  &--border-red {
    border-color: $red;
  }
  &--border-white {
    border-color: $white;
  }
  &--border-grey {
    border-color: $grey;
  }
  &--border-none {
    border-width: 0;
  }
  &__container {
    box-sizing: border-box;
    @include make-container();
    // @include make-container-max-widths();
  }
  &__row {
    @include make-row();
    &--primary {
      margin-bottom: 1em;
      align-items: center;
    }
    &--secondary {
      align-items: center;
    }
  }
  &__links {
    box-sizing: border-box;
    // @extend .col-md-7;
  }
  &__logo {
    box-sizing: border-box;
    margin-bottom: 2em;
    // @extend .col-md-5;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
    }
  }
  &__logo-link {
    display: block;
    margin: 0 auto;
    max-width: 200px;
    height: 170px;
    background-color: transparent;
    background-image: url("~@/assets/img/GEORGIA-V-CW.png");
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -9999px;
    @include media-breakpoint-up(md) {
      margin: 0;
      max-width: 300px;
      height: 80px;
      background-image: url("~@/assets/img/GEORGIA-FS-CW.png");
      background-position: left top;
    }
  }
  &__links-list {
    display: flex;
    flex-direction: row;
    margin-left: 0;
    padding-left: 0;
    list-style: none;
    text-align: center;
    line-height: 1.5;
    column-count: 2;
    column-gap: 0;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-up(md) {
      display: block;
      text-align: left;
      column-count: 2;
      align-items: flex-start;
    }
    @include media-breakpoint-up(lg) {
      column-count: 3;
    }
    &-item {
      width: 50%;
      border: none;
      break-inside: avoid;
      @include media-breakpoint-up(md) {
        width: 100%;
      }
    }
    &-link {
      display: block;
      padding: 0.25em 1em;
      font-size: 0.75em;
      @include footer-link-style;
      @include media-breakpoint-up(md) {
        border-left: 1px fade-out($white, 0.7) solid;
        font-weight: 300;
      }
    }
  }

  &__address {
    box-sizing: border-box;
    color: fade-out($white, 0.4);
    text-align: center;
    font-weight: 300;
    font-size: 0.75em;
    font-family: $font-family-sans-serif;
    // @extend .col-md-5;
    @include media-breakpoint-up(md) {
      text-align: left;
      order: 1;
    }
    &-telephone {
      @include footer-link-style();
    }
  }
  &__social {
    box-sizing: border-box;
    margin-bottom: 1em;
    color: $white;
    text-align: center;
    font-weight: 300;
    font-size: 1.5em;
    // @extend .col-md-7;
    @include media-breakpoint-up(md) {
      margin-bottom: 0;
      text-align: left;
      order: 2;
    }
    &-label {
      display: block;
      @include media-breakpoint-up(md) {
        display: inline;
      }
    }
    &-link {
      @include footer-link-style;
    }
  }
}
</style>
