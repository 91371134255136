<template>
  <section id="emergency" class="emergency-banner">
    <div v-if="errored">
      <p>Unable to reach Rave emergency alert service.</p>
    </div>

    <div v-else>
      <div v-if="!loading">
        <div v-if="emergency" class="">
          <div class="container-fluid py-5">
            <div class="row align-items-center">
              <div class="col-auto">
                <img
                  src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/PjxzdmcgaGVpZ2h0PSIxMDI0IiB3aWR0aD0iMTAyNCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTAwNS44NTQgODAwLjI0N2wtNDM4LjI4Ni03NjdDNTU2LjE3MyAxMy4zMDYwMDAwMDAwMDAwNCA1MzQuOTY3IDEgNTEyIDFzLTQ0LjE3MyAxMi4zMDYtNTUuNTY3IDMyLjI0N2wtNDM4LjI4NiA3NjdjLTExLjMxOSAxOS44MDktMTEuMjM4IDQ0LjE0NCAwLjIxMyA2My44NzZDMjkuODExIDg4My44NTUgNTAuODk5IDg5NiA3My43MTQgODk2aDg3Ni41NzJjMjIuODE0IDAgNDMuOTAzLTEyLjE0NSA1NS4zNTQtMzEuODc3UzEwMTcuMTczIDgyMC4wNTYgMTAwNS44NTQgODAwLjI0N3pNNTc2IDc2OEg0NDhWNjQwaDEyOFY3Njh6TTU3NiA1NzZINDQ4VjMyMGgxMjhWNTc2eiIvPjwvc3ZnPg=="
                  class="emergency-banner__icon"
                  alt="alert icon"
                />
              </div>
              <div class="col">
                <div class="emergency-banner__heading">
                  {{ headingDisplay }}
                </div>
                <div class="emergency-banner__message mb-3" v-html="message" />
                <Btn
                  :href="`https://emergency.uga.edu/`"
                  color="black"
                  :outline="true"
                  size="sm"
                  >More Information</Btn
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import xml2js from 'xml2js';
import Btn from '@/components/Btn.vue';

export default {
  name: 'EmergencyBanner',
  data() {
    return {
      message: '',
      loading: true,
      errored: false,
      emergency: false,
      headingDisplay: '',
    };
  },
  components: {
    Btn,
  },
  props: {
    heading: {
      type: String,
      default: 'UGA Alert Emergency Notification System',
    },
    messageOverride: {
      type: String,
      default: null,
    },
    force: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  created: function() {
    this.fetchEmergencyStatus();
  },
  methods: {
    async fetchEmergencyStatus() {
      let raveURL = 'https://www.getrave.com/rss/uga/channel2';
      // let raveURL = '/test.xml';
      this.axios
        .get(raveURL)
        .then((response) => {
          xml2js.parseString(response.data, (err, result) => {
            if (err) {
              this.errored = true;
              console.log(err);
            } else {
              let currentResult = result.rss.channel[0].item[0];

              console.log(currentResult);
              let raveStatus = currentResult.description[0];

              // If the raveStatus is not `clear`, `close`, or empty, trigger an emergency
              if (
                raveStatus != '' &&
                'clear' != raveStatus.toLowerCase() &&
                'close' != raveStatus.toLowerCase()
              ) {
                this.emergency = true;
                this.headingDisplay = currentResult.title[0];
                this.message = raveStatus;
                return;
              }

              // Use the `force` property to force the banner to display (true) or hide (false).
              // Use the `messageOverride` property to display a custom message.
              else if (this.$props.force && this.$props.messageOverride) {
                this.emergency = true;
                this.headingDisplay = this.$props.heading;
                this.message = this.$props.messageOverride;
              }
            }
          });
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.emergency-banner {
  display: none;
  background-color: $yellow;
  a {
    // color: $white;
  }
  &__icon {
    max-width: 75px;
    opacity: .5;
  }
  &__heading {
    font-size: 1.5rem;
    @include heading-base;
    margin-bottom: 0.5rem;
  }
  &__message {
    font-weight: 400;
    a {
      color: $black;
      text-decoration: underline;
    }
  }
}
</style>
